import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useState, useContext } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ThemeContext from "../../../context/ThemeContext";

const Header = ({ pushContent }) => {
  const { prismicHeader } = useStaticQuery(graphql`
    {
      prismicHeader {
        data {
          logo {
            gatsbyImageData
            alt
          }
          menu_items {
            title {
              text
            }
            link
          }
        }
      }
    }
  `);

  const themeState = useContext(ThemeContext);
  const [isVisible, setIsVisible] = useState(false);

  const shrinkCursor = (e) => {
    // themeState.toggleCursorBackground('#fff');
    themeState.shrinkCursor(0.5);
  };
  const shrinkWithColorChange = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("none");
    themeState.scaleCursor(1);
  };

  const openSidebar = () => {
    if (!isVisible) {
      setIsVisible(true);
      pushContent(true);
      return;
    }

    closeSidebar();
  };

  const closeSidebar = () => {
    setIsVisible(false);
    pushContent(false);
  };

  // const changeBorder = () => {
  //   themeState.toggleCursorBorder(2, 'white');
  // }

  // const resetBorder = () => {
  //   themeState.toggleCursorBorder();
  // }

  return (
    <nav className="min-w-[250px] md:max-w-[1440px] md:mx-auto mt-[18px] md:mt-[46px]">
      <div
        className={isVisible ? "overlay active" : "overlay"}
        onClick={() => closeSidebar()}
      ></div>
      <header className="main-nav flex justify-between items-center mx-[17px] md:mx-[34px]">
        <Link
          to="/"
          className="logo -ml-[4px]"
          onMouseEnter={shrinkCursor}
          onMouseLeave={resetCursor}
        >
          <GatsbyImage
            image={prismicHeader.data.logo.gatsbyImageData}
            alt={prismicHeader.data.logo.alt || "Logo"}
            loading="eager"
            objectFit="contain"
            className="w-[55px]"
          />
        </Link>
        <div
          className="menu-btn-container"
          onMouseEnter={shrinkCursor}
          onMouseLeave={resetCursor}
          onClick={() => openSidebar()}
        >
          <div className="w-[25px] h-[25px] bg-black rounded-full"></div>
          {/* <div className="square"> */}
          {/* <div className="menu-dot dot-1"></div>
            <div className="menu-dot dot-2"></div>
            <div className="menu-dot dot-3"></div> */}
          {/* </div> */}
        </div>
      </header>
      <div
        className={isVisible ? "sidebar-container active" : "sidebar-container"}
      >
        <div className="sidebar relative">
          <div
            className="close-btn p-2 text-xl absolute text-black right-4 top-2.5 md:top-9"
            onClick={() => closeSidebar()}
            onMouseEnter={shrinkWithColorChange}
            onMouseLeave={resetCursor}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="nav-links">
            {prismicHeader.data.menu_items.map((item, index) => (
              <Link
                key={index}
                onMouseEnter={shrinkWithColorChange}
                onMouseLeave={resetCursor}
                className="cursor-pointer link uppercase"
                to={item.link}
                onClick={() => closeSidebar()}
              >
                {item.title.text}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
