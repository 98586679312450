import React from 'react';
import { createContext, useState } from 'react';

const ThemeContext = createContext(null);

const defaultState = {
    cursorRef: null,
    cursorChild: null,
    setCursorRef: function(parent,child) {
        this.cursorRef = parent;
        this.cursorChild = child;
    },
    scaleCursor: function(val, isChanged) {
        if(!this.cursorRef) return; 
        if(isChanged){
            this.cursorRef.style.transformOrigin = `70% 70%`;
            this.cursorRef.style.transform = `scale(${val})`;
            return;
        }else{
            this.cursorRef.style.transform = `translate(-50%, -50%)`;
        }
    },
    shrinkCursor: function(val){
        if(!this.cursorRef) return;

        this.cursorRef.style.transformOrigin = `-50% -50%`;
        this.cursorRef.style.transform = `scale(${val})`;
        // this.cursorRef.style.transform = `translate(-50%, -50%)`;
    },
    toggleCursorBackground: function(col){
        if(!this.cursorRef) return; 
        this.cursorRef.style.background = `${col}`;
    },
    toggleCursorBorder: function(type){
        if(!this.cursorChild) return;
        if(type){
            this.cursorChild.style.border = type;
        }else{
            this.cursorChild.style.border = 'none';
        }
    },
    changeCursorIndex: function(index){
        if(!this.cursorRef) return;
        this.cursorRef.style.zIndex = index;
    },
    toggleCursorBlendMode: function(mode){
        if(!this.cursorRef) return;
        if(mode){
            this.cursorRef.style.mixBlendMode = mode;
        }else{
            this.cursorRef.style.mixBlendMode = 'unset';
        }
    }
}

export function ThemeProvider({children}){
    return (
        <ThemeContext.Provider value={defaultState}>{children}</ThemeContext.Provider>
    )
}

export default ThemeContext;