import React, { useEffect } from "react";
import { useState, useRef, useContext } from "react";
import Footer from "../contact";
import Header from "./header";
import ThemeContext from "../../context/ThemeContext";
import { isMobile } from "react-device-detect";

const Layout = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldPush, setShouldPush] = useState(false);
  const cursorRef = useRef();
  const cursorChildRef = useRef();
  const themeState = useContext(ThemeContext);

  const trackCursor = (e) => {
    cursorRef.current.style.left = `${e.clientX}px`;
    cursorRef.current.style.top = `${e.clientY}px`;
  }

  const addCursor = (e) => {
    setIsVisible(true);
  }
  const removeCursor = (e) => {
    setIsVisible(false);
  }
  
  useEffect(() => {
    themeState.setCursorRef(cursorRef.current,cursorChildRef.current);
    if(isMobile){
      return;
    }
    setIsVisible(true);
    window.addEventListener('mousemove', trackCursor);
    document.addEventListener('mouseenter', addCursor);
    document.addEventListener('mouseleave', removeCursor);
    return () => {
      document.removeEventListener('mouseenter', addCursor);
      document.removeEventListener('mouseleave', removeCursor);
      window.removeEventListener('mousemove', trackCursor);
    }
  }, []);

  const pushContent = (isSidebarActive) => {
    setShouldPush(isSidebarActive);
  }

  return (
    <div className={shouldPush ? 'wrapper push' : 'wrapper'}>
      <Header pushContent={pushContent}/>
      {children}
      <div className={isVisible ? "cursor active" : "cursor"} ref={cursorRef}>
        <div className="cursor-child" ref={cursorChildRef}>
          <div className="grand-child"></div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
