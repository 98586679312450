exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-brands-jsx": () => import("./../../../src/pages/brands.jsx" /* webpackChunkName: "component---src-pages-brands-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-digital-showroom-jsx": () => import("./../../../src/pages/digitalShowroom.jsx" /* webpackChunkName: "component---src-pages-digital-showroom-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mr-jsx": () => import("./../../../src/pages/mr.jsx" /* webpackChunkName: "component---src-pages-mr-jsx" */),
  "component---src-pages-showroom-jsx": () => import("./../../../src/pages/showroom.jsx" /* webpackChunkName: "component---src-pages-showroom-jsx" */)
}

