import React from "react";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";

const Contact = ({ data }) => {
  const themeState = useContext(ThemeContext);

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground('transparent');
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  return (
    <section className="footer flex flex-col justify-center items-center gap-y-2 max-w-[1440px] mx-auto">
      <div className="mt-2">
        <h4 className="text-center mb-2 uppercase">Mail</h4>
        <a
          href={`mailto:${data.mail}`}
          className="uppercase text-center mb-2"
          onMouseEnter={shrinkCursor}
          onMouseLeave={resetCursor}
        >
          <span className="backdrop">{data.mail}</span>
        </a>
      </div>
      <div className="mt-2">
        <h4 className="text-center mb-2 uppercase">Telefon</h4>
        <a
          href={`tel:${data.telefon}`}
          className="uppercase text-center mb-2"
          onMouseEnter={shrinkCursor}
          onMouseLeave={resetCursor}
        >
          <span className="backdrop">{data.telefon}</span>
        </a>
      </div>
      <div className="mt-2">
        <h4 className="text-center mb-2 uppercase">Showroom</h4>
        <p className="uppercase text-center mb-2">{data.showroom}</p>
      </div>
    </section>
  );
};

export default Contact;
